var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6 mx-auto",attrs:{"fluid":""}},[_c('v-card',{staticClass:"border-radius-xl card-shadow pa-4"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Сургуулийн дүнгийн хяналт /Жилийн эцсийн дүн/ ")]),(_vm.userData.role == 'superadmin')?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Select School","return-object":"","item-text":"name2","item-value":"id","items":_vm.schools},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-typo"},[_c('div',[_vm._v(" "+_vm._s(item.name2)+" ")])])]),_c('v-col',{staticClass:"text-end",attrs:{"lg":"4","md":"4","sm":"4"}},[(
                      item['retriedSendingGradesToESIS-' + item.currentYear]
                    )?_c('span',{staticClass:"orange--text"},[_vm._v("PRESSED")]):_vm._e(),(
                      item[
                        '_closing' + item.currentYear + (item.currentYear + 1)
                      ]
                    )?_c('span',{staticClass:"green--text ml-2"},[_vm._v("OK")]):_vm._e()])],1)]}}],null,false,2104055631),model:{value:(_vm.selectedSchool),callback:function ($$v) {_vm.selectedSchool=$$v},expression:"selectedSchool"}})],1),(_vm.selectedSchool)?_c('v-col',{staticClass:"pl-4",attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":_vm.selectedViewType ? 'Дэлгэрэнгүй' : 'Xураангуй'},model:{value:(_vm.selectedViewType),callback:function ($$v) {_vm.selectedViewType=$$v},expression:"selectedViewType"}})],1):_vm._e(),_c('v-spacer'),(_vm.userData.role == 'superadmin' && _vm.selectedSchool)?_c('v-col',{staticClass:"text-end",attrs:{"cols":"3"}},[(
              _vm.selectedSchool[
                '_closing' +
                  _vm.selectedSchool.currentYear +
                  (_vm.selectedSchool.currentYear + 1)
              ]
            )?_c('v-btn',{staticClass:"bg-gradient-success",attrs:{"dark":""},on:{"click":function($event){return _vm._addClosing(_vm.selectedSchool)}}},[_vm._v("Бүx юм ОК("+_vm._s(_vm.userData.role)+")")]):_c('v-btn',{staticClass:"bg-gradient-danger",attrs:{"dark":""},on:{"click":function($event){return _vm._addClosing(_vm.selectedSchool)}}},[_vm._v("ДУТУУ байна("+_vm._s(_vm.userData.role)+")")])],1):_vm._e()],1):_vm._e(),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e(),(_vm.selectedViewType && _vm.renderComponent)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.lessons)?_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"blue--text"},[_vm._v(" ESIS-рүү "),_c('strong',{staticClass:"red--text"},[_vm._v("шинэчилж")]),_vm._v(" хадгалагдаагүй дүнгүүд ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.sendAllLessonGradesToEsis(_vm.lessons)}}},[_vm._v("ESIS-рүү БҮХ дүнг дахин илгээх")])],1),_c('v-data-table',{staticClass:"unelgee2table",attrs:{"hide-default-footer":"","headers":_vm.headerNames,"items":_vm.lessons,"item-key":"uniqueId","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.print(props.item)}}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',[_vm._v(_vm._s(props.item.courseInfo.COURSE_NAME))]),(
                    props.item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                  )?_c('td',[_c('small',{staticClass:"red--text"},[_vm._v("Сонгон")])]):_c('td',[_c('small',{staticClass:"blue--text"},[_vm._v("Заавал")])]),_c('td',[_vm._v(_vm._s(props.item.classGroups[0].classGroupFullName))]),_c('td',[(
                      props.item.byTeachers &&
                      props.item.byTeachers.length > 0
                    )?_c('span',[_vm._v(" "+_vm._s(props.item.byTeachers .map((teacher) => teacher.teacherDisplayName) .join(", "))+" ")]):_c('span',{staticClass:"red--text"},[_c('v-icon',{staticClass:"mr-1 mt-n1",attrs:{"color":"red"}},[_vm._v(" mdi-alert ")]),_vm._v("Багшгүй ")],1)]),_c('td',[(props.item.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):(props.item.done)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"green"}},[_vm._v("Дүн хадгаласан")]):_c('v-btn',{staticClass:"white--text",attrs:{"disabled":props.item.finalGradeImpossible ||
                      (_vm.programs.find(
                        (program) =>
                          program.id == props.item.classGroupRefs[0].id
                      ) &&
                        (_vm.programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        )._esis_graduated ||
                          _vm.programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esisClosing)),"x-small":"","color":"blue"},on:{"click":function($event){return _vm.preSendCheck(props.item)}}},[_vm._v("ESIS-рүү дахин илгээх")]),(
                      props.item.finalGradeImpossible ||
                      (_vm.programs.find(
                        (program) =>
                          program.id == props.item.classGroupRefs[0].id
                      ) &&
                        (_vm.programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        )._esis_graduated ||
                          _vm.programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esisClosing))
                    )?_c('v-btn',{attrs:{"text":"","x-small":"","color":"red"}},[_vm._v(" Анги дэвшсэн! ")]):(!props.item.done)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"orange"},on:{"click":function($event){return _vm.setAsImpossibleLesson(props.item)}}},[_vm._v("Анги дэвшсэн мэдэгдэх")]):_vm._e(),(!props.item.gradesFinalized)?_c('v-btn',{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){return _vm.finalizeGrades(props.item)}}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1):_c('v-btn',{attrs:{"icon":"","color":"green","small":""},on:{"click":function($event){return _vm.finalizeGrades(props.item)}}},[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1)],1)])]}}],null,false,2526729168)})],1):_vm._e(),(_vm.notSentLessons)?_c('v-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"red--text"},[_vm._v("ESIS-рүү хадгалагдаагүй дүнгүүд")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.sendAllLessonGradesToEsis(_vm.notSentLessons)}}},[_vm._v("ESIS-рүү БҮХ дүнг илгээх")])],1),_c('v-data-table',{staticClass:"unelgee2table",attrs:{"hide-default-footer":"","headers":_vm.headerNames,"items":_vm.notSentLessons,"item-key":"uniqueId","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.print(props.item)}}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',[_vm._v(_vm._s(props.item.courseInfo.COURSE_NAME))]),(
                    props.item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                  )?_c('td',[_c('small',{staticClass:"red--text"},[_vm._v("Сонгон")])]):_c('td',[_c('small',{staticClass:"blue--text"},[_vm._v("Заавал")])]),_c('td',[_vm._v(_vm._s(props.item.classGroups[0].classGroupFullName))]),_c('td',[(
                      props.item.byTeachers &&
                      props.item.byTeachers.length > 0
                    )?_c('span',[_vm._v(" "+_vm._s(props.item.byTeachers .map((teacher) => teacher.teacherDisplayName) .join(", "))+" ")]):_c('span',{staticClass:"red--text"},[_c('v-icon',{staticClass:"mr-1 mt-n1",attrs:{"color":"red"}},[_vm._v(" mdi-alert ")]),_vm._v("Багшгүй ")],1)]),_c('td',[(props.item.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):(props.item.done)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"green"}},[_vm._v("Дүн хадгаласан")]):_c('v-btn',{staticClass:"white--text",attrs:{"disabled":props.item.finalGradeImpossible ||
                      (_vm.programs.find(
                        (program) =>
                          program.id == props.item.classGroupRefs[0].id
                      ) &&
                        (_vm.programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        )._esis_graduated ||
                          _vm.programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esisClosing)),"x-small":"","color":"red"},on:{"click":function($event){return _vm.preSendCheck(props.item)}}},[_vm._v("ESIS-рүү илгээх")]),_c('br'),(
                      props.item.finalGradeImpossible ||
                      (_vm.programs.find(
                        (program) =>
                          program.id == props.item.classGroupRefs[0].id
                      ) &&
                        (_vm.programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        )._esis_graduated ||
                          _vm.programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esisClosing))
                    )?_c('v-btn',{attrs:{"text":"","x-small":"","color":"red"}},[_vm._v(" Анги дэвшсэн! ")]):(!props.item.done)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"orange"},on:{"click":function($event){return _vm.setAsImpossibleLesson(props.item)}}},[_vm._v("Анги дэвшсэн мэдэгдэх")]):_vm._e(),(!props.item.gradesFinalized)?_c('v-btn',{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){return _vm.finalizeGrades(props.item)}}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1):_c('v-btn',{attrs:{"icon":"","color":"green","small":""},on:{"click":function($event){return _vm.finalizeGrades(props.item)}}},[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1)],1)])]}}],null,false,1645339224)})],1):_vm._e()],1):_c('v-row',{attrs:{"no-gutters":""}},[(_vm.lessons)?_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"blue--text"},[_vm._v("Дүн ESIS-рүү шинчлээгүй")])]),(_vm.filteredClassGroups)?_c('v-data-table',{staticClass:"unelgee2table",attrs:{"hide-default-footer":"","headers":[
              {
                text: 'No',
                width: '1%',
                value: 'index',
              },
              {
                text: 'Бүлгийн нэр',
                sortable: true,
                width: '10%',
              },
              {
                text: 'Xичээлийн тоо',
                sortable: true,
              },
              {
                text: 'Xичээлүүд',
                sortable: true,
              },
            ],"items":_vm.filteredClassGroups,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.print(props.item)}}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.classGroupName))]),_c('td',[_vm._v(" "+_vm._s(_vm._getNumberOfProblems(props.item).length)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._getNumberOfProblems(props.item) .map((ll) => ll.courseInfo.COURSE_NAME) .join(", "))+" ")])])]}}],null,false,3056099556)}):_vm._e()],1):_vm._e(),(_vm.notSentLessons)?_c('v-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"red--text"},[_vm._v("Дүн ESIS-рүү хадгалагдаггүй")])]),(_vm.filteredClassGroups2)?_c('v-data-table',{staticClass:"unelgee2table",attrs:{"hide-default-footer":"","headers":[
              {
                text: 'No',
                width: '1%',
                value: 'index',
              },
              {
                text: 'Бүлгийн нэр',
                sortable: true,
                width: '10%',
              },
              {
                text: 'Xичээлийн тоо',
                sortable: true,
              },
              {
                text: 'Xичээлүүд',
                sortable: true,
              },
            ],"items":_vm.filteredClassGroups2,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.print(props.item)}}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.classGroupName))]),_c('td',[_vm._v(" "+_vm._s(_vm._getNumberOfProblems2(props.item).length)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._getNumberOfProblems2(props.item) .map((ll) => ll.courseInfo.COURSE_NAME) .join(", "))+" ")])])]}}],null,false,1506544324)}):_vm._e()],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }