<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card class="border-radius-xl card-shadow pa-4">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Сургуулийн дүнгийн хяналт /Жилийн эцсийн дүн/
        </h5>
        <v-row no-gutters v-if="userData.role == 'superadmin'">
          <v-col cols="6">
            <v-select
              label="Select School"
              v-model="selectedSchool"
              return-object
              item-text="name2"
              item-value="id"
              :items="schools"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col cols="8">
                    <span class="text-typo">
                      <div>
                        {{ item.name2 }}
                      </div>
                    </span>
                  </v-col>
                  <v-col class="text-end" lg="4" md="4" sm="4">
                    <span
                      class="orange--text"
                      v-if="
                        item['retriedSendingGradesToESIS-' + item.currentYear]
                      "
                      >PRESSED</span
                    >
                    <span
                      class="green--text ml-2"
                      v-if="
                        item[
                          '_closing' + item.currentYear + (item.currentYear + 1)
                        ]
                      "
                      >OK</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>

          <v-col class="pl-4" cols="2" v-if="selectedSchool">
            <v-checkbox
              v-model="selectedViewType"
              :label="selectedViewType ? 'Дэлгэрэнгүй' : 'Xураангуй'"
            ></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            class="text-end"
            cols="3"
            v-if="userData.role == 'superadmin' && selectedSchool"
          >
            <v-btn
              v-if="
                selectedSchool[
                  '_closing' +
                    selectedSchool.currentYear +
                    (selectedSchool.currentYear + 1)
                ]
              "
              class="bg-gradient-success"
              dark
              @click="_addClosing(selectedSchool)"
              >Бүx юм ОК({{ userData.role }})</v-btn
            >
            <v-btn
              v-else
              class="bg-gradient-danger"
              dark
              @click="_addClosing(selectedSchool)"
              >ДУТУУ байна({{ userData.role }})</v-btn
            >
          </v-col>
        </v-row>

        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-row no-gutters v-if="selectedViewType && renderComponent">
          <v-col cols="6" class="pr-1" v-if="lessons">
            <v-row no-gutters class="mb-3">
              <h3 class="blue--text">
                ESIS-рүү <strong class="red--text">шинэчилж</strong> хадгалагдаагүй дүнгүүд
              </h3>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="blue"
                class="white--text"
                @click="sendAllLessonGradesToEsis(lessons)"
                >ESIS-рүү БҮХ дүнг дахин илгээх</v-btn
              >
            </v-row>
            <v-data-table
              hide-default-footer
              class="unelgee2table"
              :headers="headerNames"
              :items="lessons"
              item-key="uniqueId"
              :items-per-page="-1"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td @click.stop="print(props.item)">
                    {{ props.index + 1 }}
                  </td>
                  <td>{{ props.item.courseInfo.COURSE_NAME }}</td>
                  <td
                    v-if="
                      props.item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                    "
                  >
                    <small class="red--text">Сонгон</small>
                  </td>
                  <td v-else><small class="blue--text">Заавал</small></td>
                  <td>{{ props.item.classGroups[0].classGroupFullName }}</td>
                  <td>
                    <span
                      v-if="
                        props.item.byTeachers &&
                        props.item.byTeachers.length > 0
                      "
                    >
                      {{
                        props.item.byTeachers
                          .map((teacher) => teacher.teacherDisplayName)
                          .join(", ")
                      }}
                    </span>
                    <span v-else class="red--text">
                      <v-icon color="red" class="mr-1 mt-n1"> mdi-alert </v-icon
                      >Багшгүй
                    </span>
                  </td>
                  <td>
                    <v-progress-linear
                      v-if="props.item.loading"
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                    <v-btn
                      v-else-if="props.item.done"
                      x-small
                      color="green"
                      class="white--text"
                      >Дүн хадгаласан</v-btn
                    >
                    <v-btn
                      :disabled="
                        props.item.finalGradeImpossible ||
                        (programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        ) &&
                          (programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esis_graduated ||
                            programs.find(
                              (program) =>
                                program.id == props.item.classGroupRefs[0].id
                            )._esisClosing))
                      "
                      v-else
                      x-small
                      color="blue"
                      class="white--text"
                      @click="preSendCheck(props.item)"
                      >ESIS-рүү дахин илгээх</v-btn
                    >

                    <v-btn
                      v-if="
                        props.item.finalGradeImpossible ||
                        (programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        ) &&
                          (programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esis_graduated ||
                            programs.find(
                              (program) =>
                                program.id == props.item.classGroupRefs[0].id
                            )._esisClosing))
                      "
                      text
                      x-small
                      color="red"
                    >
                      Анги дэвшсэн!
                    </v-btn>
                    <v-btn
                      v-else-if="!props.item.done"
                      x-small
                      color="orange"
                      class="white--text"
                      @click="setAsImpossibleLesson(props.item)"
                      >Анги дэвшсэн мэдэгдэх</v-btn
                    >

                    <v-btn
                      icon
                      color="red"
                      small
                      v-if="!props.item.gradesFinalized"
                      @click="finalizeGrades(props.item)"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="green"
                      small
                      v-else
                      @click="finalizeGrades(props.item)"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6" class="pl-1" v-if="notSentLessons">
            <v-row no-gutters class="mb-3">
              <h3 class="red--text">ESIS-рүү хадгалагдаагүй дүнгүүд</h3>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="red"
                class="white--text"
                @click="sendAllLessonGradesToEsis(notSentLessons)"
                >ESIS-рүү БҮХ дүнг илгээх</v-btn
              >
            </v-row>
            <v-data-table
              hide-default-footer
              class="unelgee2table"
              :headers="headerNames"
              :items="notSentLessons"
              item-key="uniqueId"
              :items-per-page="-1"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td @click.stop="print(props.item)">
                    {{ props.index + 1 }}
                  </td>
                  <td>{{ props.item.courseInfo.COURSE_NAME }}</td>
                  <td
                    v-if="
                      props.item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                    "
                  >
                    <small class="red--text">Сонгон</small>
                  </td>
                  <td v-else><small class="blue--text">Заавал</small></td>
                  <td>{{ props.item.classGroups[0].classGroupFullName }}</td>
                  <td>
                    <span
                      v-if="
                        props.item.byTeachers &&
                        props.item.byTeachers.length > 0
                      "
                    >
                      {{
                        props.item.byTeachers
                          .map((teacher) => teacher.teacherDisplayName)
                          .join(", ")
                      }}
                    </span>
                    <span v-else class="red--text">
                      <v-icon color="red" class="mr-1 mt-n1"> mdi-alert </v-icon
                      >Багшгүй
                    </span>
                  </td>
                  <td>
                    <v-progress-linear
                      v-if="props.item.loading"
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                    <v-btn
                      v-else-if="props.item.done"
                      x-small
                      color="green"
                      class="white--text"
                      >Дүн хадгаласан</v-btn
                    >
                    <v-btn
                      :disabled="
                        props.item.finalGradeImpossible ||
                        (programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        ) &&
                          (programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esis_graduated ||
                            programs.find(
                              (program) =>
                                program.id == props.item.classGroupRefs[0].id
                            )._esisClosing))
                      "
                      v-else
                      x-small
                      color="red"
                      class="white--text"
                      @click="preSendCheck(props.item)"
                      >ESIS-рүү илгээх</v-btn
                    >
                    <br/>
                    <v-btn
                      v-if="
                        props.item.finalGradeImpossible ||
                        (programs.find(
                          (program) =>
                            program.id == props.item.classGroupRefs[0].id
                        ) &&
                          (programs.find(
                            (program) =>
                              program.id == props.item.classGroupRefs[0].id
                          )._esis_graduated ||
                            programs.find(
                              (program) =>
                                program.id == props.item.classGroupRefs[0].id
                            )._esisClosing))
                      "
                      text
                      x-small
                      color="red"
                    >
                      Анги дэвшсэн!
                    </v-btn>
                    <v-btn
                      v-else-if="!props.item.done"
                      x-small
                      color="orange"
                      class="white--text"
                      @click="setAsImpossibleLesson(props.item)"
                      >Анги дэвшсэн мэдэгдэх</v-btn
                    >

                    <v-btn
                      icon
                      color="red"
                      small
                      v-if="!props.item.gradesFinalized"
                      @click="finalizeGrades(props.item)"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="green"
                      small
                      v-else
                      @click="finalizeGrades(props.item)"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row no-gutters v-else>
          <v-col cols="6" class="pr-1" v-if="lessons">
            <v-row no-gutters class="mb-3">
              <h3 class="blue--text">Дүн ESIS-рүү шинчлээгүй</h3>
            </v-row>
            <v-data-table
              hide-default-footer
              class="unelgee2table"
              :headers="[
                {
                  text: 'No',
                  width: '1%',
                  value: 'index',
                },
                {
                  text: 'Бүлгийн нэр',
                  sortable: true,
                  width: '10%',
                },
                {
                  text: 'Xичээлийн тоо',
                  sortable: true,
                },
                {
                  text: 'Xичээлүүд',
                  sortable: true,
                },
              ]"
              v-if="filteredClassGroups"
              :items="filteredClassGroups"
              :items-per-page="-1"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td @click.stop="print(props.item)">
                    {{ props.index + 1 }}
                  </td>
                  <td class="blue--text">{{ props.item.classGroupName }}</td>
                  <td>
                    {{ _getNumberOfProblems(props.item).length }}
                  </td>
                  <td>
                    {{
                      _getNumberOfProblems(props.item)
                        .map((ll) => ll.courseInfo.COURSE_NAME)
                        .join(", ")
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6" class="pl-1" v-if="notSentLessons">
            <v-row no-gutters class="mb-3">
              <h3 class="red--text">Дүн ESIS-рүү хадгалагдаггүй</h3>
            </v-row>
            <v-data-table
              hide-default-footer
              class="unelgee2table"
              :headers="[
                {
                  text: 'No',
                  width: '1%',
                  value: 'index',
                },
                {
                  text: 'Бүлгийн нэр',
                  sortable: true,
                  width: '10%',
                },
                {
                  text: 'Xичээлийн тоо',
                  sortable: true,
                },
                {
                  text: 'Xичээлүүд',
                  sortable: true,
                },
              ]"
              v-if="filteredClassGroups2"
              :items="filteredClassGroups2"
              :items-per-page="-1"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td @click.stop="print(props.item)">
                    {{ props.index + 1 }}
                  </td>
                  <td class="blue--text">{{ props.item.classGroupName }}</td>
                  <td>
                    {{ _getNumberOfProblems2(props.item).length }}
                  </td>
                  <td>
                    {{
                      _getNumberOfProblems2(props.item)
                        .map((ll) => ll.courseInfo.COURSE_NAME)
                        .join(", ")
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import moment from "moment";
import { sync } from "vuex-pathify";
import axios from "axios";
export default {
  name: "RegularTablesView",
  data: () => ({
    programs: null,
    filteredClassGroups: null,
    filteredClassGroups2: null,
    selectedViewType: true,
    selectedSchool: null,
    schools: null,
    loading: false,
    lessons: null,
    notSentLessons: null,
    selectedGradingMethod: 1,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр дүнгээр",
        value: 1,
      },
      // {
      //   name: "Сүүлийн шалгалтын дүнгээр",
      //   value: 2,
      // },
    ],
    headerNames: [
      {
        text: "No",
        width: "1%",
        value: "index",
      },
      {
        text: "Хичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Хичээлийн төрөл",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Бүлэг",
        value: "classGroups[0].classGroupFullName",
        sortable: true,
        width: "5%",
        style: "color:red",
      },
      {
        text: "Багшийн нэр",
        value: "byTeachers",
        sortable: true,
      },
      {
        text: "Үйлдэл",
        value: "",
        sortable: false,
      },
    ],
    renderComponent: true,
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    if (this.userData.role != "superadmin") {
      this.selectedSchool = this.userData.school;
    } else {
      this._read();
    }
  },
  watch: {
    selectedSchool(val) {
      if (val) {
        this.selectedGradingMethod = val.GRADING_METHOD_USED_FOR_FINAL_GRADE;
        this.lessons = [];
        this.notSentLessons = [];
        this.filteredClassGroups = [];
        this.filteredClassGroups2 = [];
        this.readSchoolGrades(val);
      }
    },
  },
  methods: {
    finalizeGrades(lesson) {
      if (lesson.gradesFinalized) {
        lesson.gradesFinalized = false;
      } else {
        lesson.gradesFinalized = true;
      }
      lesson.ref
        .update({
          gradesFinalized: lesson.gradesFinalized,
        })
        .then(() => {
          this.forceRerender();
        });
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    async preSendCheck(lesson) {
      lesson.loading = true;
      this.forceRerender();
      var selectedProgram = await lesson.classGroupRefs[0].get().then((doc) => {
        let program = doc.data();
        program.ref = doc.ref;
        program.id = doc.id;
        return program;
      });
      if (
        lesson.courseInfo.SUBJECT_AREA_ID == 233 &&
        lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY"
      ) {
        var gradingMethodsHtml = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
        for (var gradingMethod of this.gradingMethods) {
          if (this.selectedGradingMethod == gradingMethod.value) {
            gradingMethodsHtml =
              gradingMethodsHtml +
              `<option selected value="${gradingMethod.value}">${gradingMethod.name}</option>`;
          } else {
            gradingMethodsHtml =
              gradingMethodsHtml +
              `<option value="${gradingMethod.value}">${gradingMethod.name}</option>`;
          }
        }

        gradingMethodsHtml = gradingMethodsHtml + "</select>";

        this.$swal
          .fire({
            title: "Жилийн эцсийн дүн бодох форматаа сонгоно уу!",
            text: "",
            html: gradingMethodsHtml,
            showCancelButton: true,
            confirmButtonText: "Xадгалаx",
            cancelButtonText: "Цуцлаx",
            reverseButtons: true,
            preConfirm: () => {
              const selectValue = document.getElementById("select-input").value;
              return selectValue;
            },
            customClass: {
              cancelButton: "swal2-cancel-button-custom",
              confirmButton: "swal2-ok-button-custom",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.selectedSchool.ref.update({
                  GRADING_METHOD_USED_FOR_FINAL_GRADE: Number(result.value),
                });
                lesson.ref.update({
                  GRADING_METHOD_USED_FOR_FINAL_GRADE: Number(result.value),
                });
                this.selectedSchool.GRADING_METHOD_USED_FOR_FINAL_GRADE =
                  Number(result.value);
                lesson.GRADING_METHOD_USED_FOR_FINAL_GRADE = Number(
                  result.value
                );
                lesson.gradingMethod = Number(result.value);
                this.$forceUpdate();
                await this.sendLessonGradesToEsis(lesson);
              }
            }
          });
      } else if (
        !selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE &&
        lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
      ) {
        var AVG_HOURS = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
        var AVG_HOURS_HTML = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
        for (var hours of AVG_HOURS) {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML + `<option value="${hours}">${hours}</option>`;
        }

        AVG_HOURS_HTML = AVG_HOURS_HTML + "</select>";

        this.$swal
          .fire({
            title: "Долоо хоногт орох дундаж ЦАГАА сонгоно уу!",
            text: "",
            html: AVG_HOURS_HTML,
            showCancelButton: true,
            confirmButtonText: "Xадгалаx",
            cancelButtonText: "Цуцлаx",
            reverseButtons: true,
            preConfirm: () => {
              const selectValue = document.getElementById("select-input").value;
              return selectValue;
            },
            customClass: {
              cancelButton: "swal2-cancel-button-custom",
              confirmButton: "swal2-ok-button-custom",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              if (result.value) {
                selectedProgram.ref.update({
                  AVG_CONTACT_HOURS_USED_FOR_ELECTIVE: Number(result.value),
                });

                await this.sendLessonGradesToEsis(lesson);
              }
            }
          });
      } else {
        await this.sendLessonGradesToEsis(lesson);
      }
      lesson.loading = false;
      this.forceRerender();
    },
    async sendLessonGradesToEsis(lesson, bulkSave) {
      console.log(lesson);

      if (
        !this.selectedSchool[
          "retriedSendingGradesToESIS-" + this.selectedSchool.currentYear
        ]
      ) {
        var tmp = {};
        tmp[
          "retriedSendingGradesToESIS-" + this.selectedSchool.currentYear
        ] = true;
        this.selectedSchool.ref.update(tmp).then(() => {
          this.selectedSchool[
            "retriedSendingGradesToESIS-" + this.selectedSchool.currentYear
          ] = true;
        });
      }
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        var requestBody = {
          schoolRef: this.selectedSchool.ref.path,
          programRef: lesson.classGroupRefs[0].path,
          lessonRef: lesson.ref.path,
        };
        var response = await axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/lessonGradesToEsis",
            requestBody,
            headers
          )
          .then((response) => {
            return response.data;
          });
        if (response.status === 200) {
          //   var lsnIndex = this.lessons.indexOf(lesson);
          //   if (lsnIndex == -1) {
          //     lsnIndex = this.notSentLessons.indexOf(lesson);
          //     this.notSentLessons = this.notSentLessons.splice(lsnIndex, 1);
          //   } else {
          //     this.lessons = this.lessons.splice(lsnIndex, 1);
          //   }

          lesson.done = true;
          this.forceRerender();
        } else {
          if (response.message == "program student pull fail") {
            this.setAsImpossibleLesson(lesson);
            this.$swal.fire("Анги дэвшсэн байна.");
          } else if (response.message == "no token") {
            this.$swal.fire("ESIS алдаа. Ахиад оролдоно уу!");
          } else if (response.message == "multiple scores") {
            this.$swal.fire("ESIS алдаа. Сурагч 1-ээс илүү дүнтэй байна!");
          } else if (response.message == "missing scores") {
            this.$swal.fire("ESIS алдаа. Хичээлийн дүн дутсан!");
          } else if (response.message == "no program plan id") {
            this.setAsImpossibleLesson(lesson);
            this.$swal.fire("Анги дэвшсэн байна.");
          } else if (response.message == "not same program plan id") {
            this.$swal.fire("ESIS алдаа. Сурагчдын хөтөлбөр зөрсөн!");
          } else if (response.message == "multiple plans") {
            this.$swal.fire(
              "ESIS алдаа. Хөтөлбөрт нэг хичээл хоёр удаа байна!"
            );
          } else if (response.message == "missing plans") {
            this.$swal.fire("ESIS алдаа. Хөтөлбөрт хичээл байхгүй байна!");
          } else if (response.message == "failed grading plan pull") {
            this.$swal.fire("ESIS алдаа. Ахиад оролдоно уу!");
          } else if (response.message == "no grading plans") {
            this.$swal.fire(
              "ESIS алдаа. Хичээлийн дүн буруу байна! 100-аас бага байх ёстой!"
            );
          } else if (response.message == "empty package") {
            this.$swal.fire("ESIS алдаа. Хичээл сурагчгүй байна!");
          }
        }
        console.log(response);
      } catch (e) {
        console.log(e);
        this.$swal.fire("ESIS алдаа. Ахиад оролдоно уу!");
        // funcFailed = true;
      }
      if (bulkSave) {
        lesson.loading = false;
        this.forceRerender();
      }

      if (
        this.lessons.filter((lsn) => lsn.loading).length == 0 &&
        this.notSentLessons.filter((lsn) => lsn.loading).length == 0
      ) {
        setTimeout(() => {
          if (
            !this.selectedSchool[
              "_closing" +
                this.selectedSchool.currentYear +
                (this.selectedSchool.currentYear + 1)
            ]
          ) {
            if (
              this.lessons.filter(
                (lsn) =>
                  !(
                    lsn.finalGradeImpossible ||
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    ) &&
                      (this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esis_graduated ||
                        this.programs.find(
                          (program) => program.id == lsn.classGroupRefs[0].id
                        )._esisClosing))
                  ) &&
                  lsn.byTeachers &&
                  lsn.byTeachers.length > 0 &&
                  !lsn.done
              ).length == 0 &&
              this.notSentLessons.filter(
                (lsn) =>
                  !(
                    lsn.finalGradeImpossible ||
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    ) &&
                      (this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esis_graduated ||
                        this.programs.find(
                          (program) => program.id == lsn.classGroupRefs[0].id
                        )._esisClosing))
                  ) &&
                  lsn.byTeachers &&
                  lsn.byTeachers.length > 0 &&
                  !lsn.done
              ).length == 0
            ) {
              var tmp2 = {};
              tmp2[
                "_closing" +
                  this.selectedSchool.currentYear +
                  (this.selectedSchool.currentYear + 1)
              ] = true;
              tmp2[
                "_closingAt" +
                  this.selectedSchool.currentYear +
                  (this.selectedSchool.currentYear + 1)
              ] = new Date();
              this.selectedSchool.ref.update(tmp2).then(() => {
                this.selectedSchool[
                  "_closing" +
                    this.selectedSchool.currentYear +
                    (this.selectedSchool.currentYear + 1)
                ] = true;
                this.selectedSchool[
                  "_closingAt" +
                    this.selectedSchool.currentYear +
                    (this.selectedSchool.currentYear + 1)
                ] = new Date();
                this.$forceUpdate();
              });
            }
          }
        }, 2000);
      }
    },
    sendAllLessonGradesToEsis(lessons) {
      lessons.forEach((lesson) => {
        if (
          !lesson.loading &&
          lesson.courseInfo.SUBJECT_AREA_ID != 233 &&
          lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY" &&
          lesson.byTeachers &&
          lesson.byTeachers.length > 0 &&
          !(
            lesson.finalGradeImpossible ||
            (this.programs.find(
              (program) => program.id == lesson.classGroupRefs[0].id
            ) &&
              (this.programs.find(
                (program) => program.id == lesson.classGroupRefs[0].id
              )._esis_graduated ||
                this.programs.find(
                  (program) => program.id == lesson.classGroupRefs[0].id
                )._esisClosing))
          )
        ) {
          lesson.loading = true;
          this.forceRerender();
          this.sendLessonGradesToEsis(lesson, true);
        }
      });
    },
    _addClosing(selectedSchool) {
      console.log(selectedSchool.ref.path);
      var dataa = {};
      var fieldd =
        "_closing" +
        selectedSchool.currentYear +
        (selectedSchool.currentYear + 1);
      if (!dataa[fieldd]) {
        dataa[fieldd] = true;
      } else {
        dataa[fieldd] = false;
      }
      selectedSchool.ref.update(dataa).then(() => {
        if (!selectedSchool[fieldd]) {
          selectedSchool[fieldd] = true;
        } else {
          selectedSchool[fieldd] = false;
        }
        this.$forceUpdate();
      });
    },
    _getNumberOfProblems(classGroup) {
      if (this.lessons)
        return this.lessons.filter(
          (cg) =>
            cg.classGroups[0].classGroupRef.id == classGroup.classGroupRef.id &&
            !cg.done
        );
      else return [];
    },
    _getNumberOfProblems2(classGroup) {
      if (this.notSentLessons)
        return this.notSentLessons.filter(
          (cg) =>
            cg.classGroups[0].classGroupRef.id == classGroup.classGroupRef.id &&
            !cg.done
        );
      else return [];
    },
    print(val) {
      console.log(val);
    },
    async readSchoolGrades(school) {
      console.log(school);
      this.loading = true;
      var lesson1 = null;
      var lesson2 = null;
      var lesson3 = null;
      var loading1 = false;
      var loading2 = false;
      var loading3 = false;
      var lessonCounter = 0;
      var totalLessonCounter = 0;

      this.programs = [];
      school.ref
        .collection("departments-" + school.currentYear)
        .where("deleted", "==", false)
        .get()
        .then((docs) => {
          docs.forEach(async (doc) => {
            await doc.ref
              .collection("programs")
              .where("deleted", "==", false)
              .get()
              .then((docs) => {
                docs.forEach(async (doc) => {
                  let program = doc.data();
                  program.id = doc.id;
                  program.ref = doc.ref;
                  this.programs.push(program);
                  this.forceRerender();
                  //   if (
                  //     !program["savedGradesToEsis-3"] ||
                  //     program["savedGradesToEsis-3"].successfulLessonCounter !=
                  //       program["savedGradesToEsis-3"].totalLessonCounter
                  //   ) {
                  await school.ref
                    .collection("lessons-" + school.currentYear)
                    .where("classGroupRefs", "array-contains", program.ref)
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let lesson = doc.data();
                        lesson.ref = doc.ref;
                        lesson.id = doc.id;
                        if (
                          !lesson.courseInfo.COURSE_NAME.includes("БЗЦ") &&
                          !lesson.finalGradeSavedToEsis &&
                          lesson.byTeachers &&
                          lesson.byTeachers.length > 0
                        ) {
                          lesson.uniqueId = "lesson2-" + lesson.id;
                          this._addLesson2(lesson);
                        }
                      });
                    });
                  //   }
                });
              });
          });
        });

      school.ref
        .collection("lessons-" + school.currentYear)
        .where("finalGradeSavedToEsis", "==", true)
        .get()
        .then((docs) => {
          totalLessonCounter = docs.size;
          console.log(docs.size, "TOTAL LESSON COUNTER");
          if (docs.size == 0) {
            this.loading = false;
            return;
          }
        });
      school.ref
        .collection("lessons-" + school.currentYear)
        .where("finalGradeSavedToEsis", "==", true)
        .where("departmentId", "in", [
          "department-1",
          "department-2",
          "department-3",
          "department-4",
          "department-5",
        ])
        .get()
        .then((docs) => {
          lessonCounter += docs.size;
          let counter = 0;
          lesson1 = [];
          if (docs.empty) {
            loading1 = true;
          }
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (
              !lesson.courseInfo.COURSE_NAME.includes("БЗЦ") &&
              lesson.byTeachers &&
              lesson.byTeachers.length > 0
            )
              await school.ref
                .collection("unelgee2-" + school.currentYear + "-scores")
                .doc(lesson.id + "-2")
                .collection("scores")
                .where(
                  "approvedAt",
                  ">",
                  lesson.finalGradeSavedToEsisAt.toDate()
                )
                .limit(1)
                .get()
                .then((docs2) => {
                  if (docs2.size > 0) {
                    lesson1.push(lesson);
                  }
                  counter++;
                  if (docs.size == counter) {
                    loading1 = true;
                  }
                });
            else {
              counter++;
              if (docs.size == counter) {
                loading1 = true;
              }
            }
          });
        });
      school.ref
        .collection("lessons-" + school.currentYear)
        .where("finalGradeSavedToEsis", "==", true)
        .where("departmentId", "in", [
          "department-6",
          "department-7",
          "department-8",
          "department-9",
        ])
        .get()
        .then((docs) => {
          lessonCounter += docs.size;
          let counter = 0;
          lesson2 = [];
          if (docs.empty) {
            loading2 = true;
          }
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (
              !lesson.courseInfo.COURSE_NAME.includes("БЗЦ") &&
              lesson.byTeachers &&
              lesson.byTeachers.length > 0
            )
              await school.ref
                .collection("unelgee2-" + school.currentYear + "-scores")
                .doc(lesson.id + "-2")
                .collection("scores")
                .where(
                  "approvedAt",
                  ">",
                  lesson.finalGradeSavedToEsisAt.toDate()
                )
                .limit(1)
                .get()
                .then((docs2) => {
                  if (docs2.size > 0) {
                    lesson2.push(lesson);
                  }
                  counter++;
                  if (docs.size == counter) {
                    loading2 = true;
                  }
                });
            else {
              counter++;
              if (docs.size == counter) {
                loading2 = true;
              }
            }
          });
        });
      school.ref
        .collection("lessons-" + school.currentYear)
        .where("finalGradeSavedToEsis", "==", true)
        .where("departmentId", "in", [
          "department-10",
          "department-11",
          "department-12",
        ])
        .get()
        .then((docs) => {
          lessonCounter += docs.size;
          let counter = 0;
          lesson3 = [];
          if (docs.empty) {
            loading3 = true;
          }
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (
              !lesson.courseInfo.COURSE_NAME.includes("БЗЦ") &&
              lesson.byTeachers &&
              lesson.byTeachers.length > 0
            )
              await school.ref
                .collection("unelgee2-" + school.currentYear + "-scores")
                .doc(lesson.id + "-2")
                .collection("scores")
                .where(
                  "approvedAt",
                  ">",
                  lesson.finalGradeSavedToEsisAt.toDate()
                )
                .limit(1)
                .get()
                .then((docs2) => {
                  if (docs2.size > 0) {
                    lesson3.push(lesson);
                  }
                  counter++;
                  if (docs.size == counter) {
                    loading3 = true;
                  }
                });
            else {
              counter++;
              if (docs.size == counter) {
                loading3 = true;
              }
            }
          });
        });
      while (totalLessonCounter == 0 || !loading1 || !loading2 || !loading3) {
        console.log("loading...");
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      if (
        totalLessonCounter > 0 &&
        lessonCounter > 0 &&
        loading1 &&
        loading2 &&
        loading3
      ) {
        if (totalLessonCounter == lessonCounter) {
          console.log("ALL LESSONS PULLED", totalLessonCounter);
        } else {
          console.log("LESSONS MISSING: ", totalLessonCounter - lessonCounter);
        }
      }
      this.lessons = [];
      lesson1.forEach((lesson) => {
        lesson.uniqueId = "lesson1-" + lesson.id;
        this._addLesson(lesson);
      });

      lesson2.forEach((lesson) => {
        lesson.uniqueId = "lesson1-" + lesson.id;
        this._addLesson(lesson);
      });
      lesson3.forEach((lesson) => {
        lesson.uniqueId = "lesson1-" + lesson.id;
        this._addLesson(lesson);
      });
      //   notSentLessons.forEach((lesson) => {
      //     lesson.reason = 2;
      //     this._addLesson(lesson);
      //   });
      this.loading = false;

      setTimeout(() => {
        if (
          !school["_closing" + school.currentYear + (school.currentYear + 1)]
        ) {
          if (
            this.lessons.filter(
              (lsn) =>
                !(
                  lsn.finalGradeImpossible ||
                  (this.programs.find(
                    (program) => program.id == lsn.classGroupRefs[0].id
                  ) &&
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    )._esis_graduated ||
                      this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esisClosing))
                ) &&
                lsn.byTeachers &&
                lsn.byTeachers.length > 0
            ).length == 0 &&
            this.notSentLessons.filter(
              (lsn) =>
                !(
                  lsn.finalGradeImpossible ||
                  (this.programs.find(
                    (program) => program.id == lsn.classGroupRefs[0].id
                  ) &&
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    )._esis_graduated ||
                      this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esisClosing))
                ) &&
                lsn.byTeachers &&
                lsn.byTeachers.length > 0
            ).length == 0
          ) {
            var tmp = {};
            tmp[
              "_closing" + school.currentYear + (school.currentYear + 1)
            ] = true;
            tmp["_closingAt" + school.currentYear + (school.currentYear + 1)] =
              new Date();
            school.ref.update(tmp).then(() => {
              school[
                "_closing" + school.currentYear + (school.currentYear + 1)
              ] = true;
              school[
                "_closingAt" + school.currentYear + (school.currentYear + 1)
              ] = new Date();
              this.$forceUpdate();
            });
          }
        }
      }, 2000);

      console.log(this.lessons);
    },
    _addLesson(lesson) {
      var cgg = lesson.classGroups[0];
      if (cgg) {
        var found = this.filteredClassGroups.find(
          (cg) => cg.classGroupRef.id == cgg.classGroupRef.id
        );
        if (!found) this.filteredClassGroups.push(cgg);
      }
      this.lessons.push(lesson);
    },
    setAsImpossibleLesson(lesson) {
      lesson.ref.update({ finalGradeImpossible: true }).then(() => {
        lesson.finalGradeImpossible = true;
        this.forceRerender();
        setTimeout(() => {
          if (
            !this.selectedSchool[
              "_closing" +
                this.selectedSchool.currentYear +
                (this.selectedSchool.currentYear + 1)
            ]
          ) {
            if (
              this.lessons.filter(
                (lsn) =>
                  !(
                    lsn.finalGradeImpossible ||
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    ) &&
                      (this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esis_graduated ||
                        this.programs.find(
                          (program) => program.id == lsn.classGroupRefs[0].id
                        )._esisClosing))
                  ) &&
                  lsn.byTeachers &&
                  lsn.byTeachers.length > 0
              ).length == 0 &&
              this.notSentLessons.filter(
                (lsn) =>
                  !(
                    lsn.finalGradeImpossible ||
                    (this.programs.find(
                      (program) => program.id == lsn.classGroupRefs[0].id
                    ) &&
                      (this.programs.find(
                        (program) => program.id == lsn.classGroupRefs[0].id
                      )._esis_graduated ||
                        this.programs.find(
                          (program) => program.id == lsn.classGroupRefs[0].id
                        )._esisClosing))
                  ) &&
                  lsn.byTeachers &&
                  lsn.byTeachers.length > 0
              ).length == 0
            ) {
              var tmp2 = {};
              tmp2[
                "_closing" +
                  this.selectedSchool.currentYear +
                  (this.selectedSchool.currentYear + 1)
              ] = true;
              tmp2[
                "_closingAt" +
                  this.selectedSchool.currentYear +
                  (this.selectedSchool.currentYear + 1)
              ] = new Date();
              this.selectedSchool.ref.update(tmp2).then(() => {
                this.selectedSchool[
                  "_closing" +
                    this.selectedSchool.currentYear +
                    (this.selectedSchool.currentYear + 1)
                ] = true;
                this.selectedSchool[
                  "_closingAt" +
                    this.selectedSchool.currentYear +
                    (this.selectedSchool.currentYear + 1)
                ] = new Date();
                this.$forceUpdate();
              });
            }
          }
        }, 2000);
      });
    },
    _addLesson2(lesson) {
      var cgg = lesson.classGroups[0];
      if (cgg) {
        var found = this.filteredClassGroups2.find(
          (cg) => cg.classGroupRef.id == cgg.classGroupRef.id
        );
        if (!found) this.filteredClassGroups2.push(cgg);
      }
      this.notSentLessons.push(lesson);
    },
    _read() {
      fb.db
        .collection("schools")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.schools = [];
          var index = 0;
          this.loading = true;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.directorName = "";
            item.directorName = item.directorLastName
              ? item.directorLastName + ", " + item.directorFirstName
              : item.directorFirstName;
            item.managerName = item.managerLastName
              ? item.managerLastName + ", " + item.managerFirstName
              : item.managerFirstName;

            item.index = ++index;
            item.name2 = item.index + ". " + item.name;
            item.loading = false;
            item.loadingGoups = false;
            item.loadingTeachers = false;
            if (!item._esisContracted)
              item.ref.update({ currentYear: 2023, _esisContracted: true });

            this.schools.push(item);
          });
          this.loading = false;
        });
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.unelgee2table td {
  padding-left: 10px !important;
  border: 1px solid #dddddd;
  height: 15px !important;
}

.unelgee2table th {
  padding-left: 10px !important;
  border: 1px solid #dddddd;
  height: 15px !important;
}

.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}
</style>
